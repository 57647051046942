import { createRouter, createWebHistory } from 'vue-router';
import Auth from '../views/Auth.vue';
import AllServices from '../views/AllServices.vue';
import Profile from '../views/Profile.vue';
import Users from '../views/admin/Users.vue';
import UserProfileForAdmin from '../views/admin/UserProfileForAdmin.vue';
import Logs from '../views/admin/Logs.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Авторизация',
    component: Auth,
    meta: {title: 'Авторизация'}
  },
  {
    path: '/recovery=:hash',
    name: 'Восстановление пароля',
    component: Auth,
    meta: {title: 'Восстановление пароля'}
  },
  {
    path: '/services',
    name: 'Сервисы',
    component: AllServices,
    meta: {title: 'Сервисы'}
  },
  {
    path: '/profile',
    name: 'Профиль',
    component: Profile,
    meta: {title: 'Мой профиль'}
  },
  {
    path: '/admin/users',
    name: 'Пользователи',
    component: Users,
    meta: {title: 'Пользователи'}
  },
  {
    path: '/admin/users/:id',
    name: 'Пользователь',
    component: UserProfileForAdmin,
    meta: {title: 'Пользователь'}
  },
  {
    path: '/admin/logs',
    name: 'Действия пользователей',
    component: Logs,
    meta: {title: 'Действия пользователей'}
  },
  {
    path: '/:notFound(.*)',
    component: NotFound,
    meta: {title: 'Страница не найдена'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
