<template>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <router-view></router-view>
</template>

<script>
  export default {
    watch: {
      $route(to) {
          document.title = to.meta.title + ' | STARTA' || 'STARTA';
      },
    }    
  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;

}
  h1 {
    font-weight: 400;
  }
  a {
    text-decoration: none;
  }

.p-breadcrumb {
  padding: 5px 10px !important;
  border: none !important;
  .p-menuitem-text {
    font-size: 14px !important;
  }
  .pi-home {
    font-size: 14px !important;
  }
  &-chevron {
    font-size: 10px !important;
  }


}

.p-toast {
  max-width: 87vw;
}
</style>