export default class UserService {

    getAllUsers() {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('action', 'getAllUsers');
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    getUserByID(id) {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('action', 'getUserByID');
        formData.append('id', id);
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    createUser(formData) {
        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    resetPassword(id) {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('action', 'resetPassword');
        formData.append('id', id);
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    reauthUser(id) {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('action', 'reauthUser');
        formData.append('id', id);
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    updateUser(formData) {
        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                console.log(data);
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    removeUser(id) {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('action', 'removeUser');
        formData.append('id', id);
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' '){
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getLogs() {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('action', 'getLogs');
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

}