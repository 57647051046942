<template>
  <div class="surface-0 overflow-hidden">
    <the-header></the-header>
    <the-crumbs :items="crumbsItems"></the-crumbs>
    <div class="grid px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 flex" v-if="!loading">
      <page-title :title="'Мой профиль'"></page-title>
      <form @submit.prevent="updateProfile" v-if="userData" class="col-12 grid">
        <div class="surface-section col-12">
          <ul class="list-none p-0 m-0">
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">
                <Avatar
                  :image="'http://id.starta.eco/uploads/' + userData.photo"
                  class="mr-2"
                  size="xlarge"
                  shape="circle"
                  v-if="userData.photo"
                />
                <Avatar icon="pi pi-user" class="mr-2" size="xlarge" shape="circle" v-else />
              </div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <div class="uploader_wrap">
                  <div class="uploader_wrap_button mr-3">
                    <FileUpload
                      mode="basic"
                      name="photo[]"
                      :customUpload="true"
                      @uploader="photoUploader"
                      @select="photoSelected"
                      accept="image/*"
                      :maxFileSize="2097152"
                      :auto="true"
                      chooseLabel="Загрузить фото"
                    />
                  </div>
                  <div class="uploader_wrap_status">
                    <p v-html="uploadedPhotoStatus"></p>
                  </div>
                </div>
                <small style="display: table;">Допускаются изображения в форматах JPG, JPEG или PNG размером не более 2Мб</small>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Доступные сервисы</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <div v-html="userServices"></div>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Имя</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <InputText
                  id="firstname"
                  type="text"
                  name="firstname"
                  v-model="userData.firstname"
                  placeholder="Укажите своё имя"
                />
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Фамилия</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <InputText
                  id="lastname"
                  type="text"
                  name="lastname"
                  v-model="userData.lastname"
                  placeholder="Укажите фамилию"
                />
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">E-mail</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <InputText
                  id="email"
                  type="email"
                  name="email"
                  v-model="userData.email"
                  placeholder="Введите e-mail"
                />
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Телефон</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <div class="flex flex-wrap" style="align-items: center;">
                  <span class="p-float-label mr-1">
                    <InputMask
                      mask="+7 (999) 999-9999"
                      id="phone"
                      name="phone"
                      v-model="userData.phone"
                      aria-describedby="phone-help"
                      placeholder="+7 (___) ___-____"
                    />
                    <small id="phone-help" style="display: table;">Основной номер</small>
                  </span>
                  <span class="hidden sm:block  mr-1">&nbsp;&ndash;&nbsp;</span>
                  <span class="p-float-label">
                    <InputNumber
                      id="ext_phone"
                      v-model="userData.ext_phone"
                      name="ext_phone"
                      mode="decimal"
                      :useGrouping="false"
                      aria-describedby="ext_phone-help"
                    />
                    <small id="ext_phone-help" style="display: table;">Добавочный (необязательно)</small>
                  </span>
                </div>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Телеграм</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <InputText id="telegram" type="text" name="telegram" v-model="userData.telegram" />
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Пароль</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <span class="p-float-label">
                  <Password
                    name="password"
                    id="password"
                    v-model="newPassword"
                    toggleMask
                    aria-describedby="password-help"
                    autocomplete="off"
                  ></Password>
                </span>
                <small id="password-help">Оставьте пустым, если не собираетесь менять пароль</small>
              </div>
            </li>

            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="w-6 md:w-2">
                <Button type="submit" label="Сохранить" />
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <preloader v-else></preloader>
  </div>
</template>


<script>
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";

export default {
  data() {
    return {
      userData: null,
      newPassword: null,
      servicesData: [],
      userServices: null,
      uploadedPhotoStatus: null,
      crumbsItems: [
          {
            label: 'Мой профиль',
            to: '/profile'
          }
      ]
      // userPhoto: null,
      // userPhone: null,
      // userTelegram: null,
    };
  },
  AuthService: null,
  UserService: null,
  created() {
    this.AuthService = new AuthService();
    this.UserService = new UserService();
    this.AuthService.getServicesData().then(
      (data) => (this.servicesData = data.data)
    );
  },
  mounted() {
    this.checkAuth();
  },
  computed: {
    loading() {
      if (this.userData && this.servicesData && this.userServices) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    logout() {
      this.AuthService.logout(this);
    },
    checkAuth() {
      const that = this;
      var token = this.AuthService.getCookie("starta_token");

      if (token) {
        this.AuthService.validateToken(this).then((data) => {
          if (data.status) {
            that.userData = data.data;
            // that.userPhoto = that.userData.photo;
            that.formatUserServices(JSON.parse(that.userData.services));
          } else {
            that.$toast.add({
              severity: "error",
              // summary: "Ошибка авторизации",
              detail: data.message,
              life: 4000,
            });
            that.$router.push("/");
          }
        });
      } else {
        that.$toast.add({
          severity: "error",
          // summary: 'Необходимо авторизоваться',
          detail: "Необходимо авторизоваться",
          life: 4000,
        });
        that.$router.push("/");
      }
    },
    updateProfile() {
      var id = this.userData.id;
      var firstname = this.userData.firstname;
      var lastname = this.userData.lastname;
      var email = this.userData.email;
      var phone = this.userData.phone;
      var ext_phone = this.userData.ext_phone;
      var telegram = this.userData.telegram;
      var photo = this.userData.photo;
      var password = this.newPassword;
      var token = this.AuthService.getCookie("starta_token");

      const formData = new FormData();
      const that = this;

      formData.append("action", "updateUser");
      formData.append("id", id);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("phone", phone);
      formData.append("ext_phone", ext_phone);
      formData.append("telegram", telegram);
      formData.append("photo", photo);
      formData.append("reauth", 0);

      formData.append("token", token);

      this.AuthService.updateProfile(formData).then((data) => {
        // console.log(data);
        if (data.status) {
          that.userData = data.data;
          if (data.data.photo === "null") {
            that.userData.photo = null;
          }
          that.$toast.add({
            severity: "success",
            summary: "Профиль обновлён",
            detail: data.message,
            life: 4000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    formatUserServices(value) {
      const that = this;
      var output = "";
      value.forEach(function (k) {
        let service = that.servicesData.find((item) => item.value === k);
        output +=
          '<span class="service-badges-item" style="background-color: ' +
          service.color +
          '">' +
          service.title +
          "</span>";
      });
      that.userServices = output;
    },
    photoUploader(e) {
      var files = e.files;
      var token = this.AuthService.getCookie("starta_token");

      const that = this;
      const formData = new FormData();
      formData.append("action", "uploadPhoto");
      formData.append("token", token);
      formData.append("id", this.userData.id);
      formData.append("files", files[0]);

      this.AuthService.uploadPhoto(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            summary: "Фото обновлено",
            detail: data.message,
            life: 4000,
          });
          // that.userPhoto = data.path_photo;
          that.userData = data.data;
          if (data.data.photo === "null") {
            that.userData.photo = null;
          }
          that.uploadedPhotoStatus = 'Сохранено';
          e.files = null;
        } else {
          that.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    photoSelected(e) {
      var files = e.files;
      this.uploadedPhotoStatus = files[0].name;
    }
  },
};
</script>

<style lang="scss">
  .uploader_wrap {
    display: flex;
    align-items: center;
  }
  .p-avatar.p-avatar-xl {
    width: 120px !important;
    height: 120px !important;
    overflow: hidden;
    img {
      object-fit: cover !important;
      border: 1px #e1e1e1 solid !important;
    }
  }

    .service-badges {
      display: flex;
      flex-wrap: wrap;
      &-item {
        color: white;
        border-radius: 4px;
        line-height: 23px;
        height: 23px;
        font-size: 14px;
        padding: 1px 6px;
        margin-right: 3px;
        margin-bottom: 5px;
      }
    }
  
</style>