<template>
  <DataTable :value="users" sortField="id" :sortOrder="-1"  responsiveLayout="stack" breakpoint="980px" :paginator="true" :rows="30" class="p-datatable-sm">
    <Column field="id" header="ID" class="user_id_column" :sortable="true"></Column>
    <Column field="name" header="Имя и фамилия" class="user_name_column" :sortable="true">
      <template #body="slotProps">
        <router-link :to="'/admin/users/' + slotProps.data.id" class="user_name_column_wrap">
          <Avatar
            :image="'http://id.starta.eco/uploads/' + slotProps.data.photo"
            class="mr-2"
            shape="circle"
            v-if="slotProps.data.photo"
          />
          <Avatar icon="pi pi-user" class="mr-2" shape="circle" v-else />
          {{slotProps.data.name}}
        </router-link>
      </template>
    </Column>
    <Column field="role" header="Роль" class="user_role_column" :sortable="true">
      <template #body="slotProps">
        {{formatUserRole(slotProps.data.role)}}
      </template>
    </Column>
    <Column field="active" header="Статус" class="user_status_column" :sortable="true">
      <template #body="slotProps">
        {{formatUserStatus(slotProps.data.active)}}
      </template>
    </Column>
    <Column field="group" header="Группа" class="user_group_column" :sortable="true">
      <template #body="slotProps">
        {{formatUserGroup(slotProps.data.group)}}
      </template>
    </Column>
    <Column field="services" header="Сервисы" class="user_services_column" :sortable="true">
      <template #body="slotProps">
        <div class="service-badges" v-html="formatUserServices(slotProps.data.services)"></div>
      </template>
    </Column>
    <Column class="user_controls_column" header="Управление" >
      <template #body="slotProps">
        <div class="wrap">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mr-2"
            title="Редактировать"
            @click="edit(slotProps.data.id)"
          />
          <Button
            icon="pi pi-refresh"
            class="p-button-rounded p-button-warning mr-2"
            title="Сбросить пароль"
            @click="resetPass(slotProps.data.id)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger mr-2"
            title="Удалить"
            @click="remove(slotProps.data.id)"
          />
          <Button
            icon="pi pi-sign-out"
            :class="'p-button-rounded p-button-' + stylingReauthBtn(slotProps.data.reauth)"
            title="Сбросить авторизацию"
            @click="reauth(slotProps.data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import AuthService from '../../services/AuthService';

export default {
  data() {
    return {
      servicesData: [],
      rolesData: [],
      groupsData: [],
    }
  },
  props: ["users"],
  emits: ["editUser", "remove", "resetPass", "reauthUser"],
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
  },
  mounted() {
    this.AuthService.getServicesData().then(data => this.servicesData = data.data);
    this.AuthService.getRolesData().then(data => this.rolesData = data.data);
    this.AuthService.getGroupsData().then(data => this.groupsData = data.data);
  },
  methods: {
    edit(id) {
      this.$emit("editUser", id);
    },
    remove(id) {
        this.$emit("remove", id);
    },
    resetPass(id) {
      this.$emit("resetPass", id);
    },
    reauth(id) {
      this.$emit("reauthUser", id);
    },
    formatUserGroup(value) {
      const that = this;
      var group = that.groupsData.find(item => item.value === value);
      if (group != null) {
        return group.label;
      } else {
        return '';
      }
    },
    formatUserStatus(value) {
      switch (value) {
        case 1:
        case '1':
          return 'Активен';
        case 0:
        case '0':
          return 'Неактивен';
      }
    },
    formatUserRole(value) {
      const that = this;
      var role = that.rolesData.find(item => item.value === value);
      if (role != null) {
        return role.label;
      } else {
        return '';
      }
    },
    formatUserServices(value) {
      const that = this;
      var services_array = JSON.parse(value);
      var output = "";
      if (that.servicesData.length != 0) {
        services_array.forEach(function(k) {
          let service = that.servicesData.find(item => item.value === k);
          output += '<span class="service-badges-item" style="background-color: ' + service.color + '">' + service.title + '</span>';
        })
        return output;
      } else {
        return '';
      }
    },
    stylingReauthBtn(value) {
      if (value == 1) {
        return 'secondary p-disabled';
      } else {
        return 'help';
      }
    }
  },
};
</script>

<style lang="scss">
  .user_id_column,
  .user_name_column,
  .user_email_column,
  .user_role_column,
  .user_status_column,
  .user_group_column {
    font-size: 14px;
  }
  .user_services_column {
    .service-badges {
      display: flex;
      flex-wrap: wrap;
      &-item {
        color: white;
        border-radius: 4px;
        line-height: 19px;
        height: 19px;
        font-size: 12px;
        padding: 0px 4px;
        margin-right: 3px;
        margin-bottom: 5px;
      }
    }
  }
  .user_name_column {
    &_wrap {
      display: flex;
      align-items: center;
    }
    .p-avatar img {
      object-fit: cover;
      border: 1px #e1e1e1 solid;
    }
  }
  .user_controls_column {
    .wrap {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    .p-button {
      width: 26px !important;
      height: 26px !important;
      .pi {
        font-size: 12px !important;
      }
    }
    }
  }

  @media screen and (max-width: 960px) {
    .user_controls_column {
      padding-bottom: 20px !important;
    }
    .user_services_column {
      .service-badges {
        justify-content: flex-end;
      }
    }
    .p-datatable .p-datatable-tbody > tr {
      margin-bottom: 20px;
      display: block;
    }
  }
</style>