<template>
  <div class="surface-0 overflow-hidden">
    <the-header></the-header>
    <the-crumbs :items="crumbsItems"></the-crumbs>
    <div
      class="grid px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 flex"
      v-if="currentUserGroup == 'admin' && !loading"
    >
      <page-title title="Профиль пользователя"></page-title>
      <div class="col-12">
        <Toolbar class="mb-4">
          <template #start>
            <Avatar
                :image="'http://id.starta.eco/uploads/' + user.photo"
                class="mr-2"
                size="xlarge"
                shape="circle"
                v-if="user.photo"
            />
            <Avatar icon="pi pi-user" class="mr-2" size="xlarge" shape="circle" v-else />
            <div class="user_generals">
              <p class="user_generals_name">{{user.firstname}} {{user.lastname}}</p>
              <p class="user_generals_role">{{userRole}}</p>
            </div>
          </template>
          <template #end>
            <Button
              label="Редактировать"
              icon="pi pi-pencil"
              class="p-button-success p-button-sm mr-2 mb-1"
              @click="showUpdateUserDialog"
            />
            <Button
              label="Сбросить пароль"
              icon="pi pi-refresh"
              class="p-button-warning p-button-sm mr-2 mb-1"
              @click="confirmResetPassword"
            />
            <Button
              label="Удалить"
              icon="pi pi-trash"
              class="p-button-danger p-button-sm mr-2 mb-1"
              @click="confirmRemoveUser"
            />
            <Button
              label="Сброcить авторизацию"
              icon="pi pi-sign-out"
              :class="'p-button-sm mb-1 p-button-' + stylingReauthBtn(user.reauth)"
              @click="reauthUser"
            />
          </template>
        </Toolbar>
        <div class="surface-section">
          <div
            class="text-500 mb-5"
          >Эта страница доступна только пользователям с правами администратора. Здесь отображаются все данные о пользователе.</div>
          <ul class="list-none p-0 m-0">
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Имя</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{user.firstname}}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Фамилия</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{user.lastname}}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">E-mail</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <a :href="'mailto:' + user.email">{{user.email}}</a>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Статус</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                  {{formatUserStatus(user.active)}}
                <!-- <div class="user_status">
                  <InputSwitch v-model="user.active" :trueValue="1" :falseValue="0" class="mr-2" />
                  {{formatUserStatus(user.active)}}
                </div> -->
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Дата регистрации</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{user.created}}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Последнее изменение</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{user.modified}}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Доступные сервисы</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <div v-html="userServices" class="flex flex-wrap"></div>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Группа</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{userGroup}}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Роль</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{userRole}}</div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Телефон</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <div v-html="userPhone"></div>
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Телеграм</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <div v-html="userTelegram"></div>
              </div>
            </li>
          </ul>
        </div>
        <Dialog
          v-model:visible="userDialogVisible"
          :style="{width: '640px'}"
          header="Редактирование пользователя"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="field col-6">
              <label for="firstname">Имя</label>
              <InputText
                id="firstname"
                v-model.trim="userParams.firstname"
                required="true"
                autofocus
              />
            </div>
            <div class="field col-6">
              <label for="lastname">Фамилия</label>
              <InputText
                id="lastname"
                v-model.trim="userParams.lastname"
                required="true"
                autofocus
              />
            </div>
            <div class="field col-6">
              <label for="email">Email</label>
              <InputText id="email" v-model.trim="userParams.email" required="true" autofocus />
            </div>
            <div class="field col-6">
              <label for="active">Активный</label>
              <br />
              <InputSwitch v-model="userParams.active" :trueValue="1" :falseValue="0" />
            </div>
            <div class="field col-6">
              <label for="services">Доступ к сервисам</label>

              <div class="field-checkbox" v-for="service in servicesData" :key="service">
                <Checkbox
                  :id="'services_' + service.value"
                  name="services"
                  :value="service.value"
                  v-model="userParams.services"
                />
                <label :for="'services_' + service.value">{{service.title}}</label>
              </div>

            </div>
            <div class="fields col-6 p-0">
              <div class="field col-12">
                <label for="group" class="mb-3">Группа (права пользователя)</label>
                <Dropdown
                  id="group"
                  v-model="userParams.group"
                  :options="groupsData"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Выберите группу"
                  @change="confirmAdmin"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value && slotProps.value.value">
                      <span>{{slotProps.value.label}}</span>
                    </div>
                    <div v-else-if="slotProps.value && !slotProps.value.value">
                      <span>{{slotProps.value}}</span>
                    </div>
                    <span v-else>{{slotProps.placeholder}}</span>
                  </template>
                </Dropdown>
              </div>
              <div class="field col-12">
                <label for="role" class="mb-3">Роль пользователя</label>
                <Dropdown
                  id="role"
                  v-model="userParams.role"
                  :options="rolesData"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Выберите роль"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value && slotProps.value.value">{{slotProps.value.label}}</div>
                    <div v-else-if="slotProps.value && !slotProps.value.value">{{slotProps.value}}</div>
                    <span v-else>{{slotProps.placeholder}}</span>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div class="field col-4">
              <label for="phone">Телефон</label>
                    <InputMask
                      mask="+7 (999) 999-9999"
                      id="phone"
                      name="phone"
                      v-model.trim="userParams.phone"
                      autofocus
                      placeholder="+7 (___) ___-____"
                    />
            </div>
            <div class="field col-4">
              <label for="ext_phone">Добавочный номер</label>
              <InputText
                id="ext_phone"
                v-model.trim="userParams.ext_phone"
                autofocus
              />
            </div>
            <div class="field col-4">
              <label for="telegram">Telegram</label>
              <InputText
                id="telegram"
                v-model.trim="userParams.telegram"
                autofocus
              />
            </div>
          </div>
          <template #footer>
            <Button
              label="Отмена"
              icon="pi pi-times"
              class="p-button-secondary p-button-text"
              @click="hideUserDialog"
            />
            <Button
              label="Создать"
              icon="pi pi-check"
              class="p-button-raised"
              @click="createUser"
              v-if="userMode === 'new'"
            />
            <Button
              label="Сохранить"
              icon="pi pi-check"
              class="p-button-raised"
              @click="updateUser"
              v-else
            />
          </template>
        </Dialog>
      </div>
    </div>
    <preloader v-else></preloader>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";

export default {
  data() {
    return {
      currentUserGroup: null, // группа текущего пользователя
      user: {}, // данные профиля просматриваемого пользователя
      groupsData: [],
      rolesData: [],
      servicesData: [],
      userRole: null,
      userGroup: null,
      userServices: null,
      userPhone: null,
      userTelegram: null,
      userDialogVisible: false,
      userMode: "upd",
      userParams: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        active: 1,
        services: [],
        group: null,
        role: null,
        phone: null,
        ext_phone: null,
        telegram: null,
        photo: null,
        reauth: 0,
      },
      crumbsItems: [
          {
            label: 'Администрирование',
            to: '/admin'
          },{
            label: 'Список пользователей',
            to: '/admin/users'
          },{
            label: 'Профиль пользователя',
            to: '/admin/users/' + this.$route.params.id
          }
      ]
    };
  },
  AuthService: null,
  UserService: null,
  created() {
    this.AuthService = new AuthService();
    this.UserService = new UserService();
    this.AuthService.getRolesData().then(
      (data) => (this.rolesData = data.data)
    );
    this.AuthService.getServicesData().then(
      (data) => (this.servicesData = data.data)
    );
    this.AuthService.getGroupsData().then(
      (data) => (this.groupsData = data.data)
    );
  },
  mounted() {
    this.checkAuth();
    this.getUserData();
  },
  computed: {
    loading() {
      if (
        this.currentUserGroup &&
        this.user &&
        this.groupsData &&
        this.rolesData &&
        this.servicesData
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    checkAuth() {
      const that = this;
      var token = this.AuthService.getCookie("starta_token");

      if (token) {
        this.AuthService.validateToken(this).then((data) => {
          if (data.status) {
            that.currentUserGroup = data.data.group;
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
            that.$router.push("/");
          }
        });
      } else {
        that.$toast.add({
          severity: "error",
          // summary: 'Необходимо авторизоваться',
          detail: "Необходимо авторизоваться",
          life: 4000,
        });
        that.$router.push("/");
      }
    },
    getUserData() {
      const that = this;
      this.UserService.getUserByID(this.$route.params.id).then((data) => {
        if (data.status) {
          that.user = data.message;
          that.formatUserRole(that.user.role);
          that.formatUserServices(that.user.services);
          that.formatUserGroup(that.user.group);
          if (that.user.phone) {
            that.formatUserPhone(that.user.phone, that.user.ext_phone);
          } else {
            that.userPhone = '<span class="no_info">не указан</span>';
          }
          if (that.user.telegram) {
            that.formatUserTelegram(that.user.telegram);
          } else {
            that.userTelegram = '<span class="no_info">не указан</span>';
          }
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    formatUserStatus(value) {
      switch (value) {
        case 1:
        case "1":
          return "Активен";
        case 0:
        case "0":
          return "Неактивен";
      }
    },
    formatUserGroup(value) {
      const that = this;
      var group = that.groupsData.find((item) => item.value === value);
      this.userGroup = group.label;
    },
    formatUserRole(value) {
      const that = this;
      var role = that.rolesData.find((item) => item.value === value);
      that.userRole = role.label;
    },
    formatUserServices(value) {
      const that = this;
      var output = "";
      value.forEach(function (k) {
        let service = that.servicesData.find((item) => item.value === k);
        output +=
          '<span class="service-badges-item" style="background-color: ' +
          service.color +
          '">' +
          service.title +
          "</span>";
      });
      that.userServices = output;
    },
    formatUserPhone(number, ext = null) {
      if (ext) {
        this.userPhone =
          '<a href="tel:' +
          number +
          "," +
          ext +
          '">' +
          number +
          ", доб." +
          ext +
          "</a>";
      } else {
        this.userPhone = '<a href="tel:' + number + '">' + number + "</a>";
      }
    },
    formatUserTelegram(value) {
      this.userTelegram =
        '<a href="https://t.me/' +
        value +
        '" target="_blank">@' +
        value +
        "</a>";
    },
    confirmRemoveUser() {
      const that = this;
      that.$confirm.require({
        message: "Вы уверены, что хотите удалить пользователя?",
        header: "Удаление пользователя",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          that.removeUser();
        },
      });
    },
    removeUser() {
      const that = this;
      this.UserService.removeUser(this.$route.params.id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
          that.$router.push('/admin/users');
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    resetPassword() {
      const that = this;
      this.UserService.resetPassword(this.$route.params.id).then((data) => {
        if (data.status) {
            that.getUserData();
            that.$toast.add({
                severity: "success",
                summary: data.message,
                detail: 'Новый пароль: ' + data.password,
                life: 6000,
            });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    reauthUser() {
      const that = this;
      this.UserService.reauthUser(this.$route.params.id).then((data) => {
        if (data.status) {
            that.getUserData();
            that.$toast.add({
                severity: "success",
                detail: data.message,
                life: 6000,
            });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    confirmResetPassword() {
      const that = this;
      that.$confirm.require({
        message: "Вы уверены, что хотите сбросить пароль?",
        header: "Сброс пароля",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          that.resetPassword();
        },
      });
    },
    confirmAdmin(e) {
      const that = this;
      if (e.value === "admin") {
        that.$confirm.require({
          message:
            "Вы уверены, что хотите дать права администратора этому пользователю?",
          header: "Группа пользователя",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            that.userParams.group = "admin";
          },
          reject: () => {
            that.userParams.group = "user";
          },
        });
      }
    },
    showUpdateUserDialog() {
      const that = this;
      this.UserService.getUserByID(this.$route.params.id).then((data) => {
        if (data.status) {
          that.userParams = data.message;
          this.userDialogVisible = true;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    updateUser() {
      const that = this;
      if (
        this.userParams.firstname &&
        this.userParams.lastname &&
        this.userParams.email &&
        this.userParams.group &&
        this.userParams.role
      ) {
        const formData = new FormData();
        var token = this.UserService.getCookie("starta_token");

        formData.append("action", "updateUser");
        formData.append("token", token);
        formData.append("id", this.userParams.id);
        formData.append("firstname", this.userParams.firstname);
        formData.append("lastname", this.userParams.lastname);
        formData.append("email", this.userParams.email);
        formData.append("services", JSON.stringify(this.userParams.services));
        formData.append("group", this.userParams.group);
        formData.append("active", this.userParams.active);
        formData.append("role", this.userParams.role);
        formData.append("phone", this.userParams.phone);
        formData.append("ext_phone", this.userParams.ext_phone);
        formData.append("telegram", this.userParams.telegram);
        formData.append("photo", this.userParams.photo);
        formData.append("reauth", 1);

        this.UserService.updateUser(formData).then((data) => {
          if (data.status) {
            that.clearUserParams();
            that.userMode = "new";
            that.userDialogVisible = false;
            that.getUserData();
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 4000,
            });
            that.clearUserParams();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: "Не заполнены обязательные поля",
          life: 4000,
        });
      }
    },
    clearUserParams() {
      this.UserParams = {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        active: 1,
        services: [],
        group: null,
        role: null,
        phone: null,
        ext_phone: null,
        telegram: null,
        photo: this.user.photo,
        reauth: 0,
      };
    },
    hideUserDialog() {
      this.userDialogVisible = false;
    },
    stylingReauthBtn(value) {
      if (value == 1) {
        return 'secondary p-disabled';
      } else {
        return 'help';
      }
    }
  },
};
</script>

<style lang="scss">
.user_generals {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  p {
    margin: 0 0 0 10px;
  }
  &_name {
    font-size: 22px;
    margin-bottom: 5px;
    width: 100%;
  }
  &_role {
    font-size: 16px;
    margin: 0;
    color: gray;
    width: 100%;
  }
  @media screen and (max-width: 540px) {
    p {
      margin: 0;
    }
  }
}
.no_info {
  color: var(--gray-400);
}
.user_status {
  display: flex;
  align-items: center;
}
.p-toolbar-group-left {
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.p-toolbar-group-right {
    display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 540px) {
.p-toolbar-group-left {
  margin-bottom: 15px;
  flex-wrap: wrap;
  .p-avatar {
    margin-bottom: 10px;
  }
}
}
</style>