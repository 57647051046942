<template>
  <div class="surface-0 overflow-hidden">
    <the-header></the-header>
    <the-crumbs :items="crumbsItems"></the-crumbs>
    <div
      class="grid px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 flex"
      v-if="currentUserGroup == 'admin' && !loading"
    >
      <page-title title="Действия пользователей"></page-title>
      <div class="col-12 log_container">
          <select ref="logs" class="w-full" multiple>
              <option v-for="string in logArray" :key="string">{{string}}</option>
          </select>
      </div>
    </div>
    <preloader v-else></preloader>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";

export default {
  data() {
    return {
      currentUserGroup: null, // группа текущего пользователя
      logArray: [],
      crumbsItems: [
        {
          label: "Администрирование",
          to: "/admin",
        },
        {
          label: "Действия пользователей",
          to: "/admin/logs",
        },
      ],
    };
  },
  AuthService: null,
  UserService: null,
  methods: {

    checkAuth() {
      const that = this;
      var token = this.AuthService.getCookie("starta_token");

      if (token) {
        this.AuthService.validateToken(this).then((data) => {
          if (data.status) {
            that.currentUserGroup = data.data.group;
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
            that.$router.push("/");
          }
        });
      } else {
        that.$toast.add({
          severity: "error",
          // summary: 'Необходимо авторизоваться',
          detail: "Необходимо авторизоваться",
          life: 4000,
        });
        that.$router.push("/");
      }
    },
    getLogs() {
        this.UserService.getLogs().then(data => {
            const that = this;
            if (data.status) {
                that.logArray = data.message;
                // that.$refs.logs.select(this.$refs.logs.lenght - 1);
            }
        });
    }
  },
  computed: {
    loading() {
      if (
        this.currentUserGroup
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.AuthService = new AuthService();
    this.UserService = new UserService();
  },
  mounted() {
    const that = this;
    this.checkAuth();
    this.getLogs();
    if (this.$refs.logs) {
        setInterval(function() {

            that.getLogs();
            let stringsCount = that.$refs.logs.length;
            if (stringsCount > 0) {
                let lastIndex = stringsCount - 1;
                that.$refs.logs.options[lastIndex].selected = true;
            }
        }, 3000);   
    }
  },
};
</script>

<style lang="scss" scoped>
.log_container {
    select {
        background-color: #212121;
        color: #ffffff;
        height: 500px;
        border: none;
        overflow: scroll;
        option {
            font-family: 'Courier New', Courier, monospace;
            font-size: 14px;
            &:checked {
                background: #212121;
                color: #ffffff;
            }
        }
        &:focus {
            outline: none;
        }
    }
}
</style>