<template>
    <div class="not_found">
        <div class="not_found_wrap">
            <h1>Нет такой страницы</h1>
            <h3>404 Page not found</h3>
            <router-link to="/services">Вернуться на главную</router-link>
        </div>
    </div>
</template>

<style lang="scss">
.not_found {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    &_wrap {
        display: block;
        text-align: center;
        h1 {
            font-size: 32px;
            margin-bottom: 2px;
            display: table;
            line-height: 1.2;
        }
        h3 {
            font-weight: 300;
            margin-top: 10px;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
</style>