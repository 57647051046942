export default class AuthService {
    
    login(formData) {
        const that = this;

        return fetch(process.env.VUE_APP_URL + "/jwt/api/login.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                if (data.status) {
                    that.setCookie("starta_token", data.jwt, 1);
                }
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    reset(formData) {

        return fetch(process.env.VUE_APP_URL + "/jwt/api/reset_user_password.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    getNewPassword(formData) {
        return fetch(process.env.VUE_APP_URL + "/jwt/api/reset_user_password.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    validateToken(context) {
        var token = this.getCookie('starta_token');
        const that = this;
        const formData = new FormData();
        formData.append('token', token);

        return fetch(process.env.VUE_APP_URL + "/jwt/api/validate_token.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                // console.log(data);
                if (data.reauth) {
                    that.logout(context);
                    data.status = false;
                    data.message = "В вашем аккаунте были произведены изменения. Необходимо снова авторизоваться."
                    return Promise.resolve(data);
                } else {
                    return Promise.resolve(data);
                }
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    logout(context) {
        this.setCookie("starta_token", "", 1);
        context.$router.push('/');
    }

    updateProfile(formData) {
        const that = this;

        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                if (data.status) {
                    that.setCookie("starta_token", data.jwt, 1);
                }
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    uploadPhoto(formData) {
        const that = this;
        return fetch(process.env.VUE_APP_URL + "/jwt/api/users.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                // console.log(data);
                if (data.status) {
                    that.setCookie("starta_token", data.jwt, 1);
                }
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + process.env.VUE_APP_COOKIE_SET_DOMAIN;
        // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=starta.eco";
        // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' '){
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getServicesData() {
        return fetch('/data/services.json').then(res => res.json());
    }

    getRolesData() {
        return fetch('/data/roles.json').then(res => res.json());
    }

    getGroupsData() {
        return fetch('/data/groups.json').then(res => res.json());
    }

}