<template>
  <div
    class="py-3 px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 flex align-items-center justify-content-between relative lg:static"
  >
    <router-link to="/services" class="flex align-items-center header_logo">
      <img src="@/assets/img/starta_logo.svg" alt="Starta" height="50" class="mr-0 lg:mr-2" />
    </router-link>
    <a
      class="cursor-pointer block lg:hidden text-700 p-ripple"
      v-ripple
      v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true,}"
    >
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <div
      class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-1 lg:px-0 z-2"
      style="top: 92%"
    >
      <ul
        class="list-none p-0 m-0 mt-3 px-3 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer"
      >
        <router-link
          to="/services"
          class="flex m-0 md:ml-2 px-0 py-2 text-900 font-medium line-height-3 p-ripple"
          v-ripple
        >
          <span>Все сервисы</span>
        </router-link>
        <li v-if="userGroup == 'admin'">
          <router-link
            to="/admin/users"
            class="flex m-0 md:ml-2 px-0 py-2 text-900 font-medium line-height-3 p-ripple"
            v-ripple
          >
            <span>Пользователи</span>
          </router-link>
        </li>
      </ul>
      <div
        class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 px-1  lg:mt-0"
      >
        <router-link to="/profile">
          <Button
            label="Мой профиль"
            class="p-button-rounded border-none ml-2 font-light text-white line-height-2 bg-blue-500"
          ></Button>
        </router-link>
        <Button
          label="Выйти"
          class="p-button-text p-button-rounded border-none font-light line-height-2 text-blue-500"
          @click="logout"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";

export default {
  // props: ['userGroup'],
  data() {
    return {
      userData: null,
      userGroup: null,
    };
  },
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
  },
  mounted() {
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      const that = this;
      var token = this.AuthService.getCookie("starta_token");

      if (token) {
        this.AuthService.validateToken(this).then((data) => {
          if (data.status) {
            that.userData = data.data;
            that.userGroup = data.data.group;
            if (data.data.services) {
              that.userServices = JSON.parse(data.data.services);
            }
          } else {
            that.$toast.add({
              severity: "error",
              summary: "Ошибка авторизации",
              detail: data.message,
              life: 4000,
            });
            that.$router.push("/");
          }
        });
      } else {
        that.$toast.add({
          severity: "error",
          detail: "Необходимо авторизоваться",
          life: 4000,
        });
        that.$router.push("/");
      }
    },
    logout() {
      this.AuthService.logout(this);
    },
  },
};
</script>

<style lang="scss">
  .router-link-active {
    color: var(--green-400) !important;
  }

  @media screen and (max-width: 540px) {
    .header_logo {
      img {
        max-width: 200px;
      }
    }
  }
</style>