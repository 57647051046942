<template>
  <div class="surface-0 overflow-hidden">
    <the-header></the-header>
    <the-crumbs :items="crumbsItems"></the-crumbs>
    <div class="grid px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 flex">
      <div class="col-12">
        <div class="surface-section py-2" v-if="!loading">
          <div class="mb-3 font-bold text-4xl">
            <span class="text-900">Одна цель, </span>
            <span class="text-blue-600">множество решений</span>
          </div>
          <div class="text-700 mb-6">Вам доступны следующие сервисы нашей экосистемы</div>
          <div class="grid services-list">
              <Card v-for="service in allowedServices" :key="service.id" class="mb-4 mr-0 sm:mb-2 md:mb-4 md:mr-4">
                  <template #header>
                      <img :src="'http://id.starta.eco/img/' + service.image"/>
                  </template>
                  <template #title>
                      {{service.title}}
                  </template>
                  <template #subtitle>
                      <a :href="service.link" target="_blank">{{service.link}}</a>
                  </template>
                  <template #content>
                      <p class="p-0 m-0">{{service.description}}</p>
                  </template>
              </Card>
          </div>
        </div>
        <preloader v-else></preloader>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/AuthService";

export default {
  data() {
    return {
      userData: null,
      userServices: null,
      servicesData: null,
      crumbsItems: [
          {
            label: 'Все сервисы',
            to: '/services'
          }
      ]
    };
  },
  AuthService: null,
  computed: {
    loading() {
      if (
        this.userData &&
        this.userServices &&
        this.servicesData &&
        this.allowedServices
      ) {
        return false;
      } else {
        return true;
      }
    },
    allowedServices() {
      const that = this;
      if (this.userServices && this.servicesData) {
        var allowed = [];
        that.userServices.forEach(function (k) {
          let service = that.servicesData.find((item) => item.value === k);
          allowed.push(service)
        });
        return allowed;
      } else {
        return null;
      }
    },
  },
  created() {
    this.AuthService = new AuthService();
    // this.AuthService.getRolesData().then(
    //   (data) => (this.rolesData = data.data)
    // );
    this.AuthService.getServicesData().then((data) => (this.servicesData = data.data));
    // this.AuthService.getGroupsData().then(
    //   (data) => (this.groupsData = data.data)
    // );
  },
  mounted() {
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      const that = this;
      var token = this.AuthService.getCookie("starta_token");

      if (token) {
        this.AuthService.validateToken(this).then((data) => {
          if (data.status) {
            that.userData = data.data;
            if (data.data.services) {
              that.userServices = JSON.parse(data.data.services);
            }
          } else {
            that.$toast.add({
              severity: "error",
              summary: "Ошибка авторизации",
              detail: data.message,
              life: 4000,
            });
            that.$router.push("/");
          }
        });
      } else {
        that.$toast.add({
          severity: "error",
          detail: "Необходимо авторизоваться",
          life: 4000,
        });
        that.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .services-list {
    .p-card {
      max-width: 280px;
    }
    .p-card-header {
      img {
        height: 120px;
      }
    }
  }
  .p-card-header img {
      width: 100%;
      object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    .services-list {
      justify-content: space-between;
      .p-card {
        max-width: 49%;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .services-list {
      .p-card {
        max-width: 100%;
      }
    }
  }
</style>