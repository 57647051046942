<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-4 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img src="@/assets/img/starta_logo.svg" alt="Starta.ID" class="mb-4 w-10 sm:w-8 md:w-6 lg:w-4" style="max-width: 300px">
            </div>
            <div class="max-w-max" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);" v-if="!hash">
                <div class="h-full w-full m-0 py-6 md:py-7 px-6 " style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));" v-if="authFormVisible">
                    <form class="w-full mx-auto" id="login_form" @submit.prevent="login" ref="loginForm">
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputText id="email" v-model="email" type="email" class="w-full mb-3" placeholder="Введите почту" style="padding:1rem;" />
                
                        <label for="password" class="block text-900 font-medium text-xl mb-2">Пароль</label>
                        <Password id="password" v-model="password"  :feedback="false"  placeholder="Введите пароль" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                        
                        <Button type="submit" label="Войти" class="w-full p-3 text-xl"></button>
                    </form>
                    <p class="reset_password_link" @click="authFormVisible = false">Восстановить пароль</p>
                </div>
                <div class="h-full w-full m-0 py-6 md:py-7 px-6 " style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));" v-else>
                    <form class="w-full mx-auto" id="reset_form" @submit.prevent="resetPassword" ref="resetForm">
                        <label for="resetEmail" class="block text-900 text-xl font-medium mb-2">Введите e-mail</label>
                        <InputText id="resetEmail" v-model="email" type="email" class="w-full mb-3" placeholder="Введите почту" style="padding:1rem;" />
                        
                        <Button type="submit" label="Восстановить" class="w-full p-3 text-xl"></button>
                    </form>
                    <p class="reset_password_link" @click="authFormVisible = true">Я помню пароль</p>
                </div>
            </div>
            <div class="max-w-max" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);" v-else>
                <div class="h-full w-full m-0 py-6 md:py-4 px-6 " style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <h2>Восстановление пароля</h2>
                    <div v-if="new_password">
                        <p class="new_password_string">Ваш новый пароль: <span>{{new_password}}</span></p>
                        <Button label="Войти" class="w-full p-3 text-xl" @click="backToLogin"></button>
                    </div>
                    <div v-else class="text-center">
                        <img src="@/assets/img/preloader.gif" style="width: 76px" alt="Загрузка...">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
    data() {
        return {
            email: '',
            password: '',
            checked: false,
            authFormVisible: true,
            hash: null,
            new_password: null,
        }
    },
    AuthService: null,
    created() {
        this.AuthService = new AuthService();
    },
    mounted() {
        this.checkAuth();
        this.hash = this.$route.params.hash;
        if (this.hash) {
            this.getNewPassword();
        }
    },
    methods: {
        login() {
            var email = this.$refs.loginForm.email.value;
            var password = this.$refs.loginForm.password.value;
            const formData = new FormData();
            const that = this;

            formData.append('email', email);
            formData.append('password', password);

            this.AuthService.login(formData).then(data => {
                if (data.status) {
                    that.$toast.add({
                        severity: 'success',
                        detail: 'С возвращением',
                        life: 4000
                    });
                    that.$router.push('/services')
                } else {
                    that.$toast.add({
                        severity: 'error',
                        summary: 'Ошибка авторизации',
                        detail: data.message,
                        life: 4000
                    });
                }
            });
        },
        resetPassword() {
            var email =  this.$refs.resetForm.resetEmail.value;
            const formData = new FormData();
            const that = this;

            formData.append('action', 'recoveryPassword');
            formData.append('email', email);

            this.AuthService.reset(formData).then(data => {
                if (data.status) {
                    that.$toast.add({
                        severity: 'success',
                        detail: data.message,
                        life: 6000
                    });
                    that.authFormVisible = true;
                } else {
                    that.$toast.add({
                        severity: 'error',
                        summary: 'Ошибка',
                        detail: data.message,
                        life: 4000
                    });
                }
            });
        },
        getNewPassword() {
            if (this.hash) {
                const that = this;
                const formData = new FormData();
                formData.append('action', 'generatePassByHash');
                formData.append('hash', this.hash);

                this.AuthService.getNewPassword(formData).then(data => {
                    if (data.status) {
                        this.new_password = data.new_password;
                    } else {
                        that.$toast.add({
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: data.message,
                            life: 4000
                        });
                    }
                })
            }
        },
        backToLogin() {
            this.hash = null;
            this.authFormVisible = true;
            this.$router.push('/');
        },
        checkAuth() {
            const that = this;
            var token = this.AuthService.getCookie('starta_token');
            if (token) {
                this.AuthService.validateToken(this).then(data => {
                    if (data.status) {
                        that.$router.push('/services');
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss">
    .new_password_string {
        font-size: 18px;
        margin-bottom: 30px;
        span {
            font-family: monospace;
            color: dimgray;
        }
    }
    .reset_password_link {
        text-decoration: underline;
        cursor: pointer;
        color: #4CAF50;
        display: table;
        &:hover {
            text-decoration: none;
        }
    }
</style>