<template>
  <div class="surface-0 overflow-hidden">
    <the-header></the-header>
    <the-crumbs :items="crumbsItems"></the-crumbs>
    <div class="grid px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 flex"  v-if="userData.group == 'admin' && !loading">
      <page-title :title="'Список пользователей'"></page-title>
      <div class="col-12">
        <Toolbar class="mb-4">
          <template #start>
            <Button
              label="Новый пользователь"
              icon="pi pi-plus"
              class="p-button-success mr-2 p-button-sm"
              @click="openUserDialog"
            />
          </template>
        </Toolbar>
        <users-list
          :users="users"
          @editUser="showUpdateUserDialog($event)"
          @remove="confirmRemoveUser($event)"
          @resetPass="confirmResetPassword($event)"
          @reauthUser="reauthUser($event)"
        ></users-list>
        <Dialog
          v-model:visible="userDialogVisible"
          :style="{width: '640px'}"
          :header="userDialogTitle"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="field col-12 sm:col-6">
              <label for="firstname">Имя</label>
              <InputText
                id="firstname"
                v-model.trim="userParams.firstname"
                required="true"
                autofocus
              />
            </div>
            <div class="field col-12 sm:col-6">
              <label for="lastname">Фамилия</label>
              <InputText
                id="lastname"
                v-model.trim="userParams.lastname"
                required="true"
                autofocus
              />
            </div>
            <div class="field col-12 sm:col-6">
              <label for="email">Email</label>
              <InputText id="email" v-model.trim="userParams.email" required="true" autofocus />
            </div>
            <div class="field col-12 sm:col-6">
              <label for="active">Активный</label>
              <br />
              <InputSwitch v-model="userParams.active" :trueValue="1" :falseValue="0" />
            </div>
            <div class="field col-12 sm:col-6">
              <label for="services">Доступ к сервисам</label>

              <div class="field-checkbox" v-for="service in servicesData" :key="service">
                <Checkbox
                  :id="'services_' + service.value"
                  name="services"
                  :value="service.value"
                  v-model="userParams.services"
                />
                <label :for="'services_' + service.value">{{service.title}}</label>
              </div>

            </div>
            <div class="fields col-12 sm:col-6 p-0">
              <div class="field col-12">
                <label for="group" class="mb-3">Группа (права пользователя)</label>
                <Dropdown
                  id="group"
                  v-model="userParams.group"
                  :options="groupsData"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Выберите группу"
                  @change="confirmAdmin"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value && slotProps.value.value">
                      <span>{{slotProps.value.label}}</span>
                    </div>
                    <div v-else-if="slotProps.value && !slotProps.value.value">
                      <span>{{slotProps.value}}</span>
                    </div>
                    <span v-else>{{slotProps.placeholder}}</span>
                  </template>
                </Dropdown>
              </div>
              <div class="field col-12">
                <label for="role" class="mb-3">Роль пользователя</label>
                <Dropdown
                  id="role"
                  v-model="userParams.role"
                  :options="rolesData"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Выберите роль"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value && slotProps.value.value">{{slotProps.value.label}}</div>
                    <div v-else-if="slotProps.value && !slotProps.value.value">{{slotProps.value}}</div>
                    <span v-else>{{slotProps.placeholder}}</span>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div class="field col-12 sm:col-4">
              <label for="phone">Телефон</label>
                    <InputMask
                      mask="+7 (999) 999-9999"
                      id="phone"
                      name="phone"
                      v-model.trim="userParams.phone"
                      autofocus
                      placeholder="+7 (___) ___-____"
                    />
            </div>
            <div class="field col-12 sm:col-4">
              <label for="ext_phone">Добавочный номер</label>
              <InputText
                id="ext_phone"
                v-model.trim="userParams.ext_phone"
                autofocus
              />
            </div>
            <div class="field col-12 sm:col-4">
              <label for="telegram">Telegram</label>
              <InputText
                id="telegram"
                v-model.trim="userParams.telegram"
                autofocus
              />
            </div>
          </div>
          <template #footer>
            <Button
              label="Отмена"
              icon="pi pi-times"
              class="p-button-secondary p-button-text"
              @click="hideUserDialog"
            />
            <Button
              label="Создать"
              icon="pi pi-check"
              class="p-button-raised"
              @click="createUser"
              v-if="userMode === 'new'"
            />
            <Button
              label="Сохранить"
              icon="pi pi-check"
              class="p-button-raised"
              @click="updateUser"
              v-else
            />
          </template>
        </Dialog>
      </div>
    </div>
    <preloader v-else></preloader>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import UsersList from "../../components/ui/layoutUsersList.vue";

export default {
  data() {
    return {
      userData: {},
      users: null,
      userDialogVisible: false,
      userMode: "new",
      userParams: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        active: 1,
        services: [],
        group: null,
        role: null,
        phone: null,
        ext_phone: null,
        telegram: null,
        photo: null,
        reauth: 0,
      },
      groupsData: null,
      rolesData: null,
      servicesData: null,
      crumbsItems: [
          {
            label: 'Администрирование',
            to: '/admin'
          },{
            label: 'Список пользователей',
            to: '/admin/users'
          }
      ]
    };
  },
  AuthService: null,
  UserService: null,
  created() {
    this.AuthService = new AuthService();
    this.UserService = new UserService();
    this.AuthService.getRolesData().then(data => this.rolesData = data.data);
    this.AuthService.getServicesData().then(data => this.servicesData = data.data);
    this.AuthService.getGroupsData().then(data => this.groupsData = data.data);
  },
  mounted() {
    this.checkAuth();
    this.getAllUsers();
  },
  computed: {
    loading() {
      if (
        this.userData &&
        this.users &&
        this.groupsData &&
        this.rolesData &&
        this.servicesData
      ) {
        return false;
      } else {
        return true;
      }
    },
    userDialogTitle() {
      if (this.userMode === "new") {
        return "Новый пользователь";
      } else {
        return this.userParams.firstname + " " + this.userParams.lastname;
      }
    },
  },
  components: {
    "users-list": UsersList,
  },
  methods: {
    checkAuth() {
      const that = this;
      var token = this.AuthService.getCookie("starta_token");

      if (token) {
        this.AuthService.validateToken(this).then((data) => {
          if (data.status) {
            that.userData = data.data;
          } else {
            that.$toast.add({
              severity: "error",
              summary: "Ошибка авторизации",
              detail: data.message,
              life: 4000,
            });
            that.$router.push("/");
          }
        });
      } else {
        that.$toast.add({
          severity: "error",
          detail: "Необходимо авторизоваться",
          life: 4000,
        });
        that.$router.push("/");
      }
    },
    clearUserParams() {
      this.UserParams = {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        active: 1,
        services: [],
        group: null,
        role: null,
        phone: null,
        ext_phone: null,
        telegram: null,
        photo: null,
        reauth: 0,
      };
    },
    openUserDialog() {
      this.userDialogVisible = true;
    },
    hideUserDialog() {
      this.userDialogVisible = false;
    },
    getAllUsers() {
      const that = this;
      this.UserService.getAllUsers().then((data) => {
        if (data.status) {
          that.users = data.message;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    confirmAdmin(e) {
      const that = this;
      if (e.value === "admin") {
        that.$confirm.require({
          message:
            "Вы уверены, что хотите дать права администратора этому пользователю?",
          header: "Группа пользователя",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            that.userParams.group = "admin";
          },
          reject: () => {
            that.userParams.group = "user";
          },
        });
      }
    },
    showUpdateUserDialog(id) {
      const that = this;
      this.userMode = "upd";
      this.UserService.getUserByID(id).then((data) => {
        if (data.status) {
          that.userParams = data.message;
          this.userDialogVisible = true;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    updateUser() {
      const that = this;
      if (
        this.userParams.firstname &&
        this.userParams.lastname &&
        this.userParams.email &&
        this.userParams.group &&
        this.userParams.role
      ) {
        const formData = new FormData();
        var token = this.UserService.getCookie("starta_token");

        formData.append("action", "updateUser");
        formData.append("token", token);
        formData.append("id", this.userParams.id);
        formData.append("firstname", this.userParams.firstname);
        formData.append("lastname", this.userParams.lastname);
        formData.append("email", this.userParams.email);
        formData.append("services", JSON.stringify(this.userParams.services));
        formData.append("group", this.userParams.group);
        formData.append("active", this.userParams.active);
        formData.append("role", this.userParams.role);
        formData.append("phone", this.userParams.phone);
        formData.append("ext_phone", this.userParams.ext_phone);
        formData.append("telegram", this.userParams.telegram);
        formData.append("photo", this.userParams.photo);
        formData.append("reauth", 1);

        this.UserService.updateUser(formData).then((data) => {
          if (data.status) {
            that.clearUserParams();
            that.userMode = "new";
            that.userDialogVisible = false;
            that.getAllUsers();
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 4000,
            });
            this.clearUserParams();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: "Не заполнены обязательные поля",
          life: 4000,
        });
      }
    },
    createUser() {
      const that = this;
      if (
        this.userParams.firstname &&
        this.userParams.lastname &&
        this.userParams.email &&
        this.userParams.group &&
        this.userParams.role
      ) {
        const formData = new FormData();
        var token = this.UserService.getCookie("starta_token");

        formData.append("action", "createUser");
        formData.append("token", token);
        formData.append("firstname", this.userParams.firstname);
        formData.append("lastname", this.userParams.lastname);
        formData.append("email", this.userParams.email);
        formData.append("services", JSON.stringify(this.userParams.services));
        formData.append("group", this.userParams.group);
        formData.append("active", this.userParams.active);
        formData.append("role", this.userParams.role);
        if (this.userParams.phone) {
          formData.append("phone", this.userParams.phone);
        }
        if (this.userParams.ext_phone) {
          formData.append("ext_phone", this.userParams.ext_phone);
        }
        if (this.userParams.telegram) {
          formData.append("telegram", this.userParams.telegram);
        }

        this.UserService.createUser(formData).then((data) => {
          if (data.status) {
            that.userMode = "new";
            that.userDialogVisible = false;
            that.getAllUsers();
            that.$toast.add({
              severity: "success",
              summary: data.message,
              detail: 'Пароль: ' + data.password,
              life: 4000,
            });
            this.clearUserParams();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: "Не заполнены обязательные поля",
          life: 4000,
        });
      }
    },
    confirmRemoveUser(id) {
      const that = this;
      that.$confirm.require({
        message: "Вы уверены, что хотите удалить пользователя?",
        header: "Удаление пользователя",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          that.removeUser(id);
        },
      });
    },
    removeUser(id) {
      const that = this;
      this.UserService.removeUser(id).then((data) => {
        if (data.status) {
          that.getAllUsers();
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    resetPassword(id) {
      const that = this;
      this.UserService.resetPassword(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            summary: data.message,
            detail: 'Новый пароль: ' + data.password,
            life: 6000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    reauthUser(id) {
      const that = this;
      this.UserService.reauthUser(id).then((data) => {
        if (data.status) {
          that.getAllUsers();
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 6000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    confirmResetPassword(id) {
      const that = this;
      that.$confirm.require({
        message: "Вы уверены, что хотите сбросить пароль?",
        header: "Сброс пароля",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          that.resetPassword(id);
        },
      });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.p-toolbar) {
  .p-toolbar-group-left, .p-toolbar-group-right {
    margin-bottom: 0;
  }
}
</style>