<template>
  <div
    class="py-1 px-3 mx-0 md:mx-6 lg:mx-12 lg:px-4 mb-4 flex align-items-center justify-content-between relative lg:static"
  >
    <Breadcrumb :home="home" :model="items" class="hidden sm:block"></Breadcrumb>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/services'}
    }
  }
};
</script>